import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import citizenCertificate from '../pdfs/services_refresher-training-approval.pdf';
import open_sans_font from '../OpenSans-VariableFont_wdth,wght.ttf';
import { uploadPdfAndGetUrl } from '../components/firebase'; // Import the upload function
import { setDoc } from 'firebase/firestore';

const generateTrainCertificate = async (formData) => {
  const existingPdfUrl = citizenCertificate;
  const existingPdfBytes = await fetch(existingPdfUrl).then(res => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(existingPdfBytes);

  // Register fontkit to enable custom font embedding
  pdfDoc.registerFontkit(fontkit);

  const reverseText = (text) => {
    if (!text) return '';
    return text.split('').reverse().join('');
};

  // Load a font that supports Hebrew characters
  const fontUrl = open_sans_font;
  const fontBytes = await fetch(fontUrl).then(res => res.arrayBuffer());
  const hebrewFont = await pdfDoc.embedFont(fontBytes);

  const pages = pdfDoc.getPages();
  const firstPage = pages[0];
  const passed = formData.passed || false;
  const year = new Date().getFullYear();
  const rangeId = formData.rangeId || '';
  const fullName = formData.fullName || 'שם משתמש';
  const teudatZehut = formData.teudatZehut || '123456789';
  const currentDate = formData.registrationDate || '';
  const gunLicenseId = formData.gunLicenseId || '';
  const street = formData.street || '';
  const houseNumber = formData.homeNumber || '';
  const city = formData.city || '';
  const zipCode = formData.zip || '';
  const phone = formData.phoneNumber || '';
  const email = formData.email || '';
  
  const pistolNumber = formData.imprintOnPistol || '';
  const pistolManufacturer = formData.pistolManufacturer || '';
  const origin = formData.origin || '';


  firstPage.drawText(gunLicenseId, { x: 250, y: 702, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });

  // First Part
  firstPage.drawText("מספר מטווח :"+reverseText(rangeId+'_'+year), { x: 100, y: 800, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(fullName, { x: 420, y: 630, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(teudatZehut, { x: 280, y: 630, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });

  firstPage.drawText(street, { x: 510, y: 608, size: 7, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(houseNumber, { x: 440, y: 608, size: 7, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(city, { x: 370, y: 608, size: 7, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(zipCode, { x: 300, y: 608, size: 7, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(email, { x: 206, y: 608, size: 6, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(phone, { x: 110, y: 608, size: 7, font: hebrewFont, color: rgb(0, 0, 0) });

  firstPage.drawText('שי לוי, קרני שומרון', { x: 380, y: 584, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText('048900029', { x: 135, y: 584, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });

  firstPage.drawText((currentDate.split(',')[0]), { x: 380, y: 560, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText('V', { x: 558, y: 558, size: 14, font: hebrewFont, color: rgb(0, 0, 0.5) });
  firstPage.drawText('V', { x: 558, y: 484, size: 14, font: hebrewFont, color: rgb(0, 0, 0.5) });

  // Second Part
  firstPage.drawText(gunLicenseId, { x: 250, y: 390, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText("מספר מטווח :"+reverseText(rangeId+'_'+year), { x: 50, y: 390, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText('מרכז', { x: 110, y: 290, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });

  firstPage.drawText(fullName, { x: 400, y: 265, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(teudatZehut, { x: 260, y: 265, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });

  firstPage.drawText(street, { x: 510, y: 250, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(houseNumber, { x: 440, y: 250, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(city, { x: 350, y: 250, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(zipCode, { x: 300, y: 250, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(email, { x: 240, y: 250, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(phone, { x: 110, y: 250, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });

  firstPage.drawText('שי לוי, קרני שומרון', { x: 330, y: 210, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText('048900029', { x: 90, y: 210, size: 8, font: hebrewFont, color: rgb(0, 0, 0)});
  firstPage.drawLine({start:{x:50,y:205},end:{x:130,y:205}, thickness:1});

  firstPage.drawText((currentDate.split(',')[0]), { x: 400, y: 185, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(pistolNumber, { x: 450, y: 163, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(pistolManufacturer, { x: 360, y: 163, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });
  firstPage.drawText(origin, { x: 250, y: 163, size: 8, font: hebrewFont, color: rgb(0, 0, 0) });

  firstPage.drawText('V', { x: 558, y: 180, size: 14, font: hebrewFont, color: rgb(0, 0, 0.5) });
  firstPage.drawText('V', { x: 558, y: 116, size: 14, font: hebrewFont, color: rgb(0, 0, 0.5) });



  

  const pdfBytes = await pdfDoc.save();
  // Create a blob from the PDF bytes
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  
  // Create a download link
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = `trainCertificate_${teudatZehut}_${fullName}_${currentDate}.pdf`;
  downloadLink.click();
  // const downloadUrl = await uploadPdfAndGetUrl(pdfBytes, 'train_certificates', `train_certificate_${teudatZehut}`);
  
  // return downloadUrl;
};

export default generateTrainCertificate;
